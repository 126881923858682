<template>
	<div id="notice">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>常规</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">学校公告</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box">
				<div class="nav-tab-bar u-f-item">
					<div class="font_14 nav-tab-item" @click="twacherTap(index)" :class="index == MeunIndex ? 'active' : ''" v-for="(item, index) in Meun" :key="index">
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="content u-f">
				<div class="content-left">
					<div class="font_blod font_16 p-t-b">发布</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 0 ? 'meun-active' : ''" @click="meunLeftTap(0)">
						<div class="iconfont icon-fabu"></div>
						<div>发布公告</div>
					</div>
					<div class="font_blod font_16 p-t-b">查看</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 3 ? 'meun-active' : ''" @click="meunLeftTap(3)">
						<div class="iconfont icon-yishenhe"></div>
						<div>全部公告</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 4 ? 'meun-active' : ''" @click="meunLeftTap(4)">
						<div class="iconfont icon-yishenhe1"></div>
						<div>重要公告</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 5 ? 'meun-active' : ''" @click="meunLeftTap(5)">
						<div class="iconfont icon-bianji"></div>
						<div>我发布的</div>
					</div>
				</div>
				<div class="content-right">
					<template v-if="meunLeftIndex == 0">
						<div class="c1">
							<div class="title">发布公告</div>
							<div class="publish">
								<div class="item u-f">
									<div>发送对象</div>
									<div class="u-f">
										<el-select v-model="teachers" multiple popper-class="el_option" style="width: 84%;max-height: 200px;overflow-y: auto;" @remove-tag="removeTag">
											<el-option
												style="display: none;"
												v-for="(item, index) in selectList"
												:key="index"
												:label="item.username"
												:value="item.teacher_id"
											></el-option>
										</el-select>
										<el-button style="margin-left: 10px;height: 40px;" @click="selectShow = true">选择</el-button>
									</div>
								</div>
								<div class="item u-f">
									<div>公告标题</div>
									<div><el-input v-model="title" placeholder="请填写公告标题" style="width: 90%;"></el-input></div>
								</div>
								<!-- <div class="item u-f">
									<div>截止时间</div>
									<div>
										<el-date-picker value-format="yyyy-MM-dd H:mm" v-model="start_time" type="datetime" placeholder="选择日期（不选默认为永久有效）" style="width: 90%;"></el-date-picker>
									</div>
								</div> -->
								<div class="item u-f">
									<div>正文</div>
									<div><mavon-editor @imgAdd="imgAdd" :subfield="false" defaultOpen="edit" ref="md" v-model="info"></mavon-editor></div>
								</div>
							</div>
							<div class="bottom u-f-item u-f-right">
								<div>
									<el-checkbox v-model="checked" style="margin-right: 10px">重要</el-checkbox>
									<!-- <el-checkbox v-model="checked" style="margin-right: 20px">同步至家长群</el-checkbox>
									<el-select  v-model="main_department_id" placeholder="请选择年级" style="width: 150px;" @change="mainChanage">
										<el-option v-for="item in peopleList" :key="item.id" :label="item.username" :value="item.id"></el-option>
									</el-select> -->
									<!-- <el-button>存草稿</el-button> -->
									<el-button type="primary" @click="submit">发送</el-button>
								</div>
							</div>
						</div>
						<el-dialog custom-class="selectShow" :visible.sync="selectShow" width="800px" :before-close="handleClose" append-to-body>
							<div slot="title" class="u-f-item dialog-title"><div style="margin-right: 10px;">选择联系人</div></div>
							<div class="user-list-box">
								<div class="u-f-item u-f-jsb user-list-head">
									<div class="user-type-list u-f-item">
										<div
											class="user-type-item u-f-justify"
											@click="typeTap(index)"
											:class="typeIndex == index ? 'active' : ''"
											v-for="(item, index) in teacher_list"
											:key="index"
										>
											{{ item.name }}
											<span v-if="index + 1 < teacher_list.length">|</span>
										</div>
									</div>
									<!-- <el-input placeholder="请输入内容" prefix-icon="el-icon-search" style="width: 200px;" size="small" v-model="key"></el-input> -->
								</div>
								<div class="user-list-content u-f">
									<div class="u-f2 left" v-if="teacher_list.length">
										<div
											class="left-item"
											@click="dataTap(index)"
											:class="dataIndex == index ? 'active' : ''"
											v-for="(item, index) in teacher_list[typeIndex].data"
											:key="index"
										>
											{{ item.name }}
										</div>
									</div>
									<div class="u-f4 right" v-if="teacher_list.length">
										<div class="list">
											<div
												class="item"
												@click="teachTap(item)"
												:class="item.checked ? 'active' : ''"
												v-for="(item, index) in teacher_list[typeIndex].data[dataIndex].teacher"
											>
												{{ item.username }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
								<div class="u-f-item">
									<el-button @click="reset1" size="small">重置</el-button>
									<el-button @click="allTap" type="primary" size="small" v-if="!isBnt">全选</el-button>
									<el-button @click="allTap1" type="primary" size="small" v-if="isBnt">取消全选</el-button>
								</div>
								<div class="u-f-item">
									<span style="color: #A4A4A4;margin-right: 10px;">{{ teachers.length }}/{{ nums.length }}</span>
									<el-button type="primary" size="small" @click="selectShow = false">确 定</el-button>
								</div>
							</span>
						</el-dialog>
					</template>
					<template v-if="meunLeftIndex != 0 && !isDetails">
						<div class="c2">
							<div class="title u-f-item u-f-jsb">
								<div>
									<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" style="margin-right: 10px"></el-checkbox>
									<!-- <span>我发布的</span> -->
									<span style="color: #999;margin-left: 5px">共{{ count }}条数据</span>
								</div>
							</div>
							<div class="news">
								<el-checkbox-group v-model="building" @change="handleCheckedCitiesChange">
									<div class="item u-f-item u-f-jsb" v-for="(item, index) in list" :key="index" v-if="list.length">
										<div class="u-f-item">
											<el-checkbox :label="item.id" :key="item.id"><div class="circle">通知</div></el-checkbox>
											<div class="u-f-item" @click.stop="todetails(item.id)">
												<div class="desc u-line-1" style="width: 500px;">
													<div>{{ item.title }}</div>
													<div>{{ item.info }}</div>
												</div>
											</div>
										</div>
										<div>
											<div>发布人：{{ item.author }}</div>
											<div class="p-t-b">{{ item.createtime | getLocalTime }}</div>
										</div>
									</div>
									<el-empty description="暂无公告内容" v-if="!list.length"></el-empty>
								</el-checkbox-group>
							</div>
							<div class="u-f-item u-f-jsb" style="padding: 20px" v-if="list.length">
								<div>
									<!-- <el-button type="primary">标记为已读</el-button> -->
									<el-button @click="delMsg">删除</el-button>
								</div>
								<div>
									<el-pagination
										@size-change="handleSizeChange"
										@current-change="handleCurrentChange"
										::current-page.sync="page"
										:page-size="10"
										:total="count"
									></el-pagination>
								</div>
							</div>
						</div>
					</template>
					<template v-if="meunLeftIndex != 0 && isDetails">
						<div class="c2">
							<div class="title u-f-item u-f-jsb">
								<div class="u-f-item">
									<i
										@click="isDetails = false"
										class="el-icon-arrow-left"
										style="border: 1px solid #007AFF;border-radius: 50%;padding: 3px;color: #007AFF;margin-right: 10px;"
									></i>
									{{ info.title }}
								</div>
								<div style="color: #A4A4A4;font-size: 12px;">
									<div>发布人：{{ info.author }}</div>
									<div>{{ info.createtime | getLocalTime }}</div>
								</div>
							</div>
							<div class="news"><div style="padding:30px 50px;" v-html="info.info"></div></div>
						</div>
					</template>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import E from 'wangeditor';
import { mavonEditor } from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
export default {
	components: {
		index,
		mavonEditor
	},
	data() {
		return {
			building: [],
			Meun: [{ name: '学校公告' }],
			MeunIndex: 0,
			meunLeftIndex: 0,
			total: 0,
			activityVisible: false,
			title: '',
			sectionList: [],
			peopleList: [],
			consume_id: [],
			page: 1,
			limit: 10,
			Mylist: [],
			ids: [],
			checked: 0,
			info: '',
			list: [],
			count: 0,
			checkAll: false,
			isIndeterminate: true,
			isDetails: false,
			info: '',
			teachers: [],
			nums: [],
			selectShow: false,
			selectList: [],
			teacher_list: [],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
			isBnt:false
		};
	},
	mounted() {
		this.teacherList();
	},
	filters: {
		getLocalTime(nS) {
			return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
		}
	},
	methods: {
		// 教师列表
		teacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					for (let i in this.teacher_list) {
						for (let j in this.teacher_list[i].data) {
							for (let t in this.teacher_list[i].data[j].teacher) {
								this.nums = this.nums.concat(this.teacher_list[i].data[j].teacher[t].teacher_id);
								this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
							}
						}
					}
					this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
				}
			});
		},
		// 重置
		reset1() {
			this.teachers = [];
			this.teacherList();
			this.isBnt = false;
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		// 选择老师
		teachTap(item) {
			let arr = [];
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
						if (this.teacher_list[i].data[j].teacher[t].checked) {
							arr.push(this.teacher_list[i].data[j].teacher[t].teacher_id);
						}
					}
				}
			}
			this.teachers = arr;
			this.teachers = this.unique(this.teachers);
		},
		allTap(){
			let arr = [];
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						this.teacher_list[i].data[j].teacher[t].checked = true;
						arr.push(this.teacher_list[i].data[j].teacher[t].teacher_id);
					}
				}
			}
			this.teachers = arr;
			this.teachers = this.unique(this.teachers);
			this.isBnt = true;
		},
		allTap1(){
			this.reset1();
			this.isBnt = false;
		},
		removeTag() {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teachers.length) {
							for (let c in this.teachers) {
								if (this.teacher_list[i].data[j].teacher[t].teacher_id == this.teachers[c]) {
									this.teacher_list[i].data[j].teacher[t].checked = true;
								} else {
									this.teacher_list[i].data[j].teacher[t].checked = false;
								}
							}
						} else {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},

		handleCheckedCitiesChange(e) {
			console.log(this.building);
		},
		handleCheckAllChange(val) {
			let arr = [];
			for (let i in this.list) {
				arr.push(this.list[i].id);
			}
			this.building = val ? arr : [];
			this.isIndeterminate = false;
		},
		handleSelectionChange() {},
		// 关闭弹窗
		closeActivity() {
			this.reset();
			this.activityVisible = false;
		},
		handleClose(done) {
			this.reset();
			done();
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			if (this.meunLeftIndex == 3) {
				this.systemMsgList();
			} else if (this.meunLeftIndex == 4) {
				this.systemMsgList(1);
			} else if (this.meunLeftIndex == 5) {
				this.systemMsgpush();
			}
		},
		// 选中
		handleSelectionChange(val) {
			let arr = [];
			for (let i in val) {
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids);
		},
		// 数据重置
		reset() {
			this.title = '';
		},
		twacherTap(index) {
			this.MeunIndex = index;
		},
		meunLeftTap(index) {
			this.isDetails = false;
			this.meunLeftIndex = index;
			let type = '';
			this.isIndeterminate = true;
			this.building = [];
			this.page = 1;
			if (this.meunLeftIndex == 3) {
				this.systemMsgList();
			} else if (this.meunLeftIndex == 4) {
				this.systemMsgList(1);
			} else if (this.meunLeftIndex == 5) {
				this.systemMsgpush();
			}
		},
		systemMsgpush() {
			let data = {
				page: this.page,
				limit: this.limit
			};
			this.$api.rule.systemMsgpush(data).then(res => {
				if (res.data.code == 1) {
					this.count = res.data.data.total;
					this.list = res.data.data.rows;
				}
			});
		},
		mainChanage(e) {
			// this.userList(e)
		},
		ectypeChanage(e) {
			this.userList2(e);
		},
		// 人员列表
		userList() {
			this.$api.setting.userList().then(res => {
				if (res.data.code == 1) {
					this.peopleList = res.data.data.rows;
				}
			});
		},
		// 活动申请
		submit() {
			let data = {
				consume_id: this.teachers.toString(),
				title: this.title,
				info: this.$refs.md.d_render
			};
			if (this.checked) {
				data.level = 1;
			} else {
				data.level = 2;
			}
			this.$api.rule.addSystemMsg(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('提交成功');
					this.teachers = [];
					this.title = '';
					this.info = '';
					this.checked = 0;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		systemMsgindex(type) {
			let data = {
				page: this.page,
				limit: this.limit
			};
			if (type == 1) {
				data.filter = JSON.stringify({ level: 1 });
			}
			this.$api.rule.systemMsgindex(data).then(res => {
				if (res.data.code == 1) {
					this.count = res.data.data.total;
					this.list = res.data.data.rows;
				}
			});
		},
		systemMsgList(type) {
			let data = {
				type: 1,
				event: 20,
				page: this.page,
				limit: this.limit
			};
			if (type == 1) {
				data.filter = JSON.stringify({ level: 1 });
			}
			this.$api.rule.systemMsgList(data).then(res => {
				if (res.data.code == 1) {
					this.count = res.data.data.total;
					this.list = res.data.data.rows;
				}
			});
		},
		// 删除公告
		delMsg() {
			if (this.meunLeftIndex == 3 || this.meunLeftIndex == 4) {
				this.$api.rule
					.delSystemMsg({
						msg_id: this.building.toString()
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							if (this.meunLeftIndex == 3) {
								this.systemMsgList();
							} else if (this.meunLeftIndex == 4) {
								this.systemMsgList(1);
							}
						} else {
							this.$message.error(res.data.msg);
						}
					});
			} else {
				this.$api.rule
					.delSystemMyMsg({
						id: this.building.toString()
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.systemMsgpush();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			}
		},
		// 公告详情
		todetails(id) {
			this.$api.rule
				.systemGet({
					id: id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						this.isDetails = true;
						this.systemRead(id);
					}
				});
		},
		// 读取消息
		systemRead(id) {
			this.$api.rule
				.systemRead({
					msg_id: id
				})
				.then(res => {
					if (res.data.code == 1) {
					}
				});
		},
		imgAdd(pos, $file) {
			console.log($file);
			var formData = new FormData();
			formData.append('file', $file);
			this.$api.setting.uploadFile(formData).then(res => {
				if (res.data.code == 1) {
					this.$refs.md.$img2Url(pos, res.data.data.fullurl);
				} else {
					this.$message.error(res.data.msg);
				}
			});
		}
	}
};
</script>

<style lang="scss">
.el_option {
	display: none !important;
}
#notice {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin: 1.04vw 0;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.53125vw;
			}
		}
	}
	.content {
		background-color: #ffffff;
		height: 79vh;
		border-top: 1px solid #eeeeee;
		.content-left {
			width: 20%;
			border-right: 1px dashed #eeeeee;
			height: 72vh;
			padding: 30px 20px 30px 30px;
			.meun-item {
				cursor: pointer;
				padding: 10px 20px;
				color: #96a2aa;
				.iconfont {
					margin-right: 10px;
				}
			}
			.meun-active {
				background-color: #ddeeff;
				color: #3f9ffe;
			}
		}
		.content-right {
			width: 80%;
			height: 79vh;
			position: relative;
			overflow: hidden;
			.title {
				background: #f5f7fa;
				padding: 15px;
			}
			.c1 {
				.publish {
					padding: 20px;
					.item {
						margin-bottom: 15px;
						> div:first-child {
							padding-top: 10px;
							width: 80px;
							text-align: right;
							margin-right: 20px;
							font-size: 14px;
							font-weight: bold;
						}
						> div:last-child {
							flex: 1;
							> div {
								width: 100%;
							}
						}
					}
				}
				.bottom {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					background: #f5f7fa;
					padding: 15px;
				}
			}
			.c2 {
				.news {
					overflow-y: auto;
					height: 66vh;
					.item {
						padding: 20px;
						border-bottom: 1px solid #f5f7fa;
						.circle {
							width: 40px;
							height: 40px;
							color: #fff;
							border-radius: 50%;
							background-color: #9fcffe;
							margin-right: 10px;
							text-align: center;
							line-height: 40px;
						}
						.desc {
							> div:first-child {
								color: #000;
								margin-bottom: 5px;
								font-size: 0.85vw;
							}
							> div:last-child {
								color: #999;
								font-size: 0.45vw;
							}
						}
					}
				}
			}
		}
	}
	.content.other {
		margin-top: 10px;
		padding: 15px 20px;
		.title {
			margin-bottom: 10px;
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.edit-box {
			padding: 0 20px 0 20px;
			max-height: 60vh;
			overflow-y: auto;
			.input-box {
				margin-top: 35px;
				.input-lebal {
					width: 25%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.el-select .el-input.is-disabled .el-input__inner {
					cursor: default;
				}
				.el-input.is-disabled .el-input__inner {
					background-color: #ffffff;
					cursor: default;
				}
				.el-input__prefix,
				.el-input__suffix {
					display: none;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
